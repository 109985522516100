import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Layout } from "../layouts/Site";
import { AssertPage } from "../components/AssetContext";
import { HeaderPage, Page, PageWidth, Section } from "../components/Containers";
import { Component } from "../components/Component";
import { RichText } from "../components/RichText";
import { PageHeading } from "../components/Headings";
import { ServiceExcerpt } from "../components/Service";
import { Testimonials } from "../components/Testimonial";
import { join } from "../styles/styles";
import { Helmet } from "react-helmet";

export default function ServicesPage({ location }) {
  const { page, defaultMetadata } = useStaticQuery(query)

  return <Layout title={page.name} darkFooter={true}>
    <Helmet>
      <meta name="description" content={page.metaDescription || defaultMetadata.metaDescription} />
    </Helmet>
    <AssertPage id={page.contentful_id} url={location.pathname} />
    {page.contents && page.contents.map(createTopLevelComponent)}
  </Layout>
}

function createTopLevelComponent(content, index, allContent) {
  if (content.key === "services: advisory services")
    return <HeaderPage hideControl className="mb-12">
      <Section className='pt-4'>
        <PageHeading className='text-center'>How we can help you</PageHeading>
      </Section>
      <ServicesSection content={content} servicesList={allContent.find(c => c.key === "advisory-services-list")} />
    </HeaderPage>;

  if (content.key === "services: corporate training")
    return <Page>
      <ServicesSection content={content} servicesList={allContent.find(c => c.key === "corporate-training-list")} rightToLeft />
    </Page>

  if (content.key === "advisory-services-list"
    || content.key === "corporate-training-list")
    return null;

  if (content.__typename === "ContentfulSiteTestimonial")
    return <PageWidth className="bg-primary text-page mt-16 pt-16">
      <Section className="md:px-48 text-center">
        <Testimonials testimonials={content} />
      </Section>
    </PageWidth>;

  return <PageWidth>
    <Section>
      <Component content={content} />
    </Section>
  </PageWidth>;
}

function ServicesSection({ content, servicesList, rightToLeft }) {
  return <Section cols="2" className="border-2 border-primary rounded-3xl">
      <RichText body={content.body} className={join(rightToLeft ? "md:order-last" : "md:order-first", "order-none text-page text-center bg-primary rounded-3xl px-12 -m-1 py-24")} />
      <div className="mx-8 divide-y-2 divide-primary">
        {servicesList.contents.map(service => <ServiceExcerpt service={service} className="text-center py-8 text-sm" />)}
      </div>
    </Section>
}

const query = graphql`{
  page: contentfulSiteContentList(key: {eq: "services"}) {
    key
    name
    contentful_id
    contents {
      ... on ContentfulSiteContent {
        ...Content    
      }
      ... on ContentfulSiteTestimonial {
        ...Testimonial
      }
      ... on ContentfulSiteContentList {
        key
        name
        contents {
          ... on ContentfulSiteService {
            ...Service
          }
        }
      }
    }
  }
  defaultMetadata: contentfulSiteContentList(key: {eq: "index"}) {
    metaDescription
  }
}`;